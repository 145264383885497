import abiDissDao from '../abi/dissdao.json'
import {ethers} from "ethers";
import store from '@/store'

import Web3 from 'web3'

export const getContract = async () => {
  const {provider, connection} = store.state.web3Modal;
  const web3 = await new Web3(connection)
  window.web3 = web3
  const Contract = await new web3.eth.Contract(abiDissDao, process.env.VUE_APP_NFT_CONTRACT_ADDRESS)
  return Contract;
}

/**
 * 铸造
 * tokenID: string
royalties: string
 */
  export const mint = async () => {
    const Contract = await getContract();
    console.log('Contract', Contract);

    return new Promise((resolve, reject) => {
      Contract.methods
        .mint()
        .send({
          value: 10000000000000000,
          from: localStorage.getItem('account'),
        })
        .then(resolve)
        .catch(reject)
    })
  }

export const getPrice = async () => {
  const Contract = await getContract();
  return new Promise((resolve, reject) => {
    Contract.methods
      .getPrice()
      .call({
        from: localStorage.getItem('account'),
      })
      .then(resolve)
      .catch(reject)
  })
}

export const getTotal = async () => {
  const Contract = await getContract();
  return new Promise((resolve, reject) => {
    Contract.methods
      .getTotal()
      .call({
        from: localStorage.getItem('account'),
      })
      .then(resolve)
      .catch(reject)
  })
}

export const checkUser = async () => {
  const Contract = await getContract();
  return new Promise((resolve, reject) => {
    Contract.methods
      .checkUser()
      .call({
        from: localStorage.getItem('account'),
      })
      .then(resolve)
      .catch(reject)
  })
}

export const balanceOf = async () => {
  const Contract = await getContract();
  return new Promise((resolve, reject) => {
    Contract.methods
      .balanceOf(localStorage.getItem('account'))
      .call()
      .then(resolve)
      .catch(reject)
  })
}

export const tokenOfOwnerByIndex = async () => {
  const Contract = await getContract();
  console.log('Contract', Contract);
  return new Promise((resolve, reject) => {
    Contract.methods
      .tokenOfOwnerByIndex(localStorage.getItem('account'), 1)
      .send({
        from: localStorage.getItem('account'),
      })
      .then(resolve)
      .catch(reject)
  })
}

export const withdraw =  async () => {
  const Contract = await getContract();
  console.log('Contract', Contract);
  return new Promise((resolve, reject) => {
    Contract.methods
      .withdraw(localStorage.getItem('account'), 1)
      .send({
        from: localStorage.getItem('account'),
      })
      .then(resolve)
      .catch(reject)
  })
}

